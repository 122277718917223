<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <el-row :gutter="20" class="w-100">
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
              <p class="large--title m-0"> {{
              $t("message.edit_m", {
                m: $t("message.phoneNumberForSipAccountOutsideCall"),
              })
            }}</p>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
              <div style="    text-align: right; margin-top: 5px;">
                <crm-store-update-close
                  :permission="$options.name"
                  :button_type="'store'"
                  :loading="loadingButton"
                  @c-submit="submit(true)"
                  @c-close="close()"
                ></crm-store-update-close>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0" v-loading="loadingData">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules" label-position="top">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item :label="$t('message.name')" prop="name" class="label_mini">
                            <crm-input :size="'medium'" :inputValue="form.name" v-model="form.name" :placeholder="$t('message.name')"></crm-input>
                        </el-form-item>
                    </el-col>
                    <!-- end-col -->

                    <el-col :span="24">
                      <el-form-item
                      prop="prefix"
                      :label="$t('message.prefix')"
                      >
                        <el-input
                            :placeholder="$t('message.prefix')"
                            v-model="form.prefix"
                            size="medium"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <!-- end-col -->

                    <el-col :span="24">
                        <crm-phone-input
                            :inputValue="form.phone_number"
                            v-model="form.phone_number"
                            @getPhoneStatus="getPhoneStatus"
                        ></crm-phone-input>
                    </el-col>
                    <!-- end-col -->

                </el-row>
            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
export default {
    name: "phoneNumberForSipAccountOutsideCall",
    mixins: [form, drawer, show],
    data() {
        return {};
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "phoneNumberForSipAccountOutsideCall/rules",
            model: "phoneNumberForSipAccountOutsideCall/model",
            columns: "phoneNumberForSipAccountOutsideCall/columns",
        }),
    },
    methods: {
        ...mapActions({
            update: "phoneNumberForSipAccountOutsideCall/update",
            show: "phoneNumberForSipAccountOutsideCall/show",
        }),
        getPhoneStatus(is_valid){
          this.is_phone_valid = is_valid;
        },
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.update(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (res.status == 200) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    },
};
</script>
